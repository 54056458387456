/**
 * 任务相关类型定义
 * @author wzwwu
 */
import { PrivateTags, PublicTags, Pagination, ISortOrder, AsyncTotal } from './common'
import { CustomerTypeEnum, LinkedFlagEnum } from './customer'
import { ILeadPoolItem, LeadStatusEnum } from './lead'
import { OpportunityWinRateEnum } from './sale-opportunity'

// 待跟进任务过滤器 坐席工作台
export interface TaskFilter extends ISortOrder, Pagination, AsyncTotal{
  leadId?: number // 线索ID
  name?: string // 客户名称
  touchStatus?: number[] // 触达状态
  intentionId?: number[] // 一级客户意向ID
  subIntentionId?: number[] // 二级客户意向ID
  sourcePrimaryTagId?: number // 一级客户来源
  sourceSecondaryTagId?: number // 二级客户来源
  groupId?: number // 所属业务组
  follower?: string | string[] // 当前跟进人
  leadBeginTime?: string // 线索创建开始时间（格式为2020-04-17 16:46:45）
  leadEndTime?: string // 线索创建结束时间（格式为2020-04-17 16:46:45）
  publicTagId?: number // 公共标签
  privateTagId?: number // 个人标签
  lastFollowBeginTime?: string // 最后跟进开始时间（格式为2020-04-17 16:46:45）
  lastFollowEndTime?: string // 最后跟进结束时间（格式为2020-04-17 16:46:45）
  regTimeFrom?: string // 注册时间范围开始（格式为2020-04-17 16:46:45）
  regTimeTo?: string // 注册时间范围结束（格式为2020-04-17 16:46:45）
  authTimeFrom?: string // 认证时间范围开始（格式为2020-04-17 16:46:45）
  authTimeTo?: string // 认证时间范围结束（格式为2020-04-17 16:46:45）
  qq?: string // qq
  phone?: string // 电话
  uin?: string | string[] // uin
  ruleId?: number | number[]
  ruleType?: number
  predictFollowBeginTime?:string //  "2021-02-09 00:00:00"
  predictFollowEndTime?: string // "2021-03-18 23:59:59"
  taskCreateBeginTime?: string// "2021-02-11 00:00:00"
  taskCreateEndTime?: string// "2021-03-18 23:59:59"
  assignType?: number // 任务分配方式
  taskType?: number // 任务进度
  taskName?: string // 任务名称
  sort?: 'predictFollowTime'
  dataType?: DataTypeEnum // 1归属我的数据2我管理的数据 默认是2
}

export enum DataTypeEnum {
  ME_ONLY = 1, // 1归属我的数据
  MY_TEAM = 2, // 2我管理的数据
}

// 所有任务列表过滤器
export interface AllTaskFilter extends ISortOrder, Pagination, AsyncTotal {
  leadId?: number // 线索ID
  name?: string // 客户名称
  touchStatus?: number[] // 触达状态
  intentionId?: number[] // 一级客户意向ID
  subIntentionId?: number[] // 二级客户意向ID
  ruleType?: number // 任务类型
  sourcePrimaryTagId?: number // 一级客户来源
  sourceSecondaryTagId?: number // 二级客户来源
  groupId?: number // 所属业务组
  follower?: string | string[] // 当前跟进人
  leadBeginTime?: string // 线索创建开始时间（格式为2020-04-17 16:46:45）
  leadEndTime?: string // 线索创建结束时间（格式为2020-04-17 16:46:45）
  publicTagId?: number // 公共标签
  privateTagId?: number // 个人标签
  lastFollowBeginTime?: string // 最后跟进开始时间（格式为2020-04-17 16:46:45）
  lastFollowEndTime?: string // 最后跟进结束时间（格式为2020-04-17 16:46:45）
  regTimeFrom?: string // 注册时间范围开始（格式为2020-04-17 16:46:45）
  regTimeTo?: string // 注册时间范围结束（格式为2020-04-17 16:46:45）
  authTimeFrom?: string // 认证时间范围开始（格式为2020-04-17 16:46:45）
  authTimeTo?: string // 认证时间范围结束（格式为2020-04-17 16:46:45）
  qq?: string // qq
  phone?: string // 电话
  uin?: string | string[] // uin
  ruleId?: number | number[]
  taskStatus?: TaskStatusEnum // 否 int 任务状态，0表示未处理，1表示已处理，默认全部
  taskCreateBeginTime?: string // 否 string 任务创建开始时间（格式为2020-04-17 16:46:45）
  taskCreateEndTime?: string // 否 string 任务创建结束时间（格式为2020-04-17 16:46:45）
  taskName?: string // 任务名称
  sort?: 'taskCreateTime' | 'predictFollowTime'
}

export enum TaskStatusEnum {
  HANDLED = 1, // 已处理
  UNHANDLED = 0, // 未处理
  CLOSED = 2, // 已关闭
}

export interface TaskListByCidFilter extends AsyncTotal {
  status?: TaskStatusEnum // 否 int 任务状态，0表示未处理，1表示已处理，默认全部
  cid: string
}

export interface TaskListDetail {
  taskId: number // 任务ID
  ruleId: number
  cid: string // "0773e293c3a621a3fc32941464732d52"
  createTime: string // "2021-01-22 18:30:06"
  taskName: string // "测试最近跟进时间"
  predictFollowTime: string // 任务时间(预计跟进时间)
  status: TaskStatusEnum // number  非必须
  creator: string // string  非必须
  updateTime: string // string  非必须
  belongModule: string // number  非必须
  extraFormId: number // number  非必须
  lastFollowTime: string // string  非必须

  leadInfo: ILeadPoolItem & {
    // primaryIndustryIncomeName: string // 行业名称
    // smallProductName: string //    非必须 关注产品3级
    // subProductName: string //    非必须 关注产品4级
  }
}

export interface TaskNameListFilter extends Pagination, AsyncTotal {
  taskName?: string
}

export interface TaskNameItem {
  taskName: string
}

// 全部任务项
export interface AllTaskListItem {
  authTime: string, // 认证时间 "2019-02-20 15:21:53"
  cityCode: string // 市代码 ""
  // createTime: "2020-12-31 11:40:02"
  customerId: number // 客户ID 408
  follower: string // 当前跟进人 "MaXuan"
  // groupIds: "10"
  // groupName: "公司1群组1"
  groups: { // 所属群组 [{groupId: 10, groupName: "公司1群组1", creator: "ZhouJianXun", manager: "RaoLinHai", belongModule: 1,…}]
    groupId: number
    groupName: string
    creator: string
    manager: string
    belongModule: number
    companyId: number
    createTime: string
    updateTime: string
    groupMember: string
    qidianCallNumber: string
    status: number
  }[]
  intentionId: number // 0
  intentionName: string, // ""
  lastFollowCreateTime: string // 线索创建时间 ""
  lastFollowTime: string // 最近跟进时间
  leadCreateTime:string // 最后跟进时间 "2020-12-31 11:36:21"
  leadId: number
  name: string // 客户名称 "JOSHUA开发专用"
  predictFollowTime: string // 任务时间(预计跟进时间) "2020-12-31 11:40:02"
  privateTags: PrivateTags[] // []
  provinceCode: string // 省份代码 ""
  publicTags: PublicTags[] // [{publicTagId: 63, publicTagName: "系统"}]
  regTime: string, // 注册时间 "2016-05-14 15:32:05"
  ruleId: number
  ruleName: string // ""
  ruleTypeName: string // ""
  sourcePrimaryTagId: number // 一级客户来源ID 1
  sourcePrimaryTagName: string // 一级客户来源 "高端"
  sourceSecondaryTagId: number // 二级客户来源ID 2
  sourceSecondaryTagName: string // 二级客户来源 "大气"
  subIntentionId: number, // 0
  subIntentionName: string, // ""
  taskCreateTime: string // 任务创建时间 "2020-12-31 11:40:02"
  taskId: number // 任务ID 1480
  taskName: number // 任务名称
  taskStatus: TaskStatusEnum // 否 int 任务状态，0表示未处理，1表示已处理，默认全部
  touchStatus: string // 触达状态 4
  uin: string // uin "10000"
}

// 批量处理未完成任务参数
export interface UpdateNotHandleTasksPayload {
  filter: Omit<TaskFilter, keyof (Pagination & ISortOrder)> & {
    taskIds?: number[] // 任务id
  }
  updateResult: {
    status?: number // 任务状态：1已完成
    publicTagIds?: number[] // 公共标签id
    privateTagIds?: number[] // 个人标签id
  }
}

// 获取任务类型的返回类型
export interface TaskType {
  key: number
  value: string
}
// 获取跟进方式的返回类型
export interface FollowType {
  key: string
  value: string
}

// 查询目标接口返回数据类型
export interface Goal {
  relatedId: number // 当前对象的id
  relatedType: GoalRelatedTypeEnum
  // companyId: number // 也就是根节点
  // companyName: string
  name: string // 公司， 群组， 员工
  personalCustomerGoal: number
  enterpriseCustomerGoal: number
  belongMonth: string // 所属月份，格式为202110
}

// 查询目标接口上送数据类型
export interface GoalFilter {
  parentId?: number
  parentType?: GoalRelatedTypeEnum
  relatedId?: number[]
  relatedType?: GoalRelatedTypeEnum
  belongMonth?: string // 所属月份，格式为2021-10，默认为当月
}

// 设置目标接口上送数据类型
export interface GoalParam {
  relatedId: number
  relatedType: GoalRelatedTypeEnum
  belongMonth?: string // 所属月份，格式为202110，默认为当月
  personalCustomerGoal?: number
  enterpriseCustomerGoal?: number
}

export enum GoalRelatedTypeEnum {
  COMAPNY = 3,
  GROUP = 2,
  SALESNAME = 1,
}

export interface IndicatorFilter {
  dataType?: DataTypeEnum // 1归属我的数据2我管理的数据 默认是2
  groupId?: number // 群组id，
  refresh?: number,
}

export interface Indicator {
  tag: string // 必须
  count: number // 必须
  tagName?: string
}

export interface IndicatorCustomerFilter extends Pagination, IndicatorFilter, ISortOrder, AsyncTotal{
  tag?: string // 必须
  sort?: 'createTime' | 'firstAuthTime' | 'lastFollowTime' | 'firstPayTime' | 'lastRelatedTime'
  cid?: string | string[] // cid
  uin?: string | string[] // uin
  name?: string // 否 string 客户名称
  follower?: string // 否 string array 当前跟进人，多个人名使用数组形式，["DuJianHao", "MaXuan"]，空字符串表示无跟进人，可以''或者['']
  lastFollower?: string // 否 string array 最近跟进人，多个人名使用数组形式，["DuJianHao", "MaXuan"]
  customerType?: CustomerTypeEnum // 否 int 客户类型0个人1企业2政府3组织
  publicTagId?: number | number[] // 公共标签
  privateTagId?: number | number[] // 个人标签
  createTimeFrom?: string // 否 timestamp 起始创建时间
  createTimeTo?: string // 否 timestamp 结束创建时间
  firstAuthTimeFrom?: string // 否 timestamp 首次认证开始时间
  firstAuthTimeTo?: string // 否 timestamp 首次认证结束时间
  lastFollowBeginTime?: string // 否 string 最近跟进开始时间
  lastFollowEndTime?: string // 否 string 最近跟进结束时间
  lastRelatedTimeFrom?: string // 否 timestamp 最近关联时间起始时间
  lastRelatedTimeTo?: string // 否 timestamp 最近关联时间结束时间
  predictFollowTimeFrom?: string // 否 timestamp 下次跟进时间起始时间
  predictFollowTimeTo?: string // 否 timestamp 下次跟进时间结束时间
  relatedStatus?: LinkedFlagEnum // 否 int 关联状态0代分配1已关联2:考察中
  leadId?: number // 否 int 线索ID
  leadStatus?: LeadStatusEnum // 是 int 线索分类（0未处理，1已分配，2废弃, 3已处理）
  batchId?: number | number[] // 批次ID
  leadName?: string // 否 string 线索名称
  leadBeginTime?: string // 否 string 线索创建开始时间（格式为2020-04-17 16:46:45）
  leadEndTime?: string // 否 string 线索创建结束时间（格式为2020-04-17 16:46:45）
  leadAssignTimeFrom?: string // 否 string 线索变更时间开始时间（格式为2020-04-17 16:46:45）
  leadAssignTimeTo?: string // 否 string 线索变更时间时间（格式为2020-04-17 16:46:45）
  firstAssignTimeFrom?: string // 否 string 首次分配开始时间（格式为2020-04-17 16:46:45）
  firstAssignTimeTo?: string // 否 string 首次分配结束时间（格式为2020-04-17 16:46:45）
  firstAssigner?: string[] // 否 array 首次分配人
  firstFollowTimeFrom?: string // 否 string 首次跟进开始时间（格式为2020-04-17 16:46:45）
  firstFollowTimeTo?: string // 否 string 首次跟进结束时间（格式为2020-04-17 16:46:45）
  firstFollower?: string[] // 否 array 首次跟进人
  leadPublicTagId?: number // 否 int 系统标签
  leadPrivateTagId?: number // 否 int 个人标签
  opportunityId?: number // 否 int 销售机会ID
  opportunityName?: string // 否 string 销售机会名称
  moneyFrom?: number // 否 int 销售机会金额
  moneyTo?: number // 否 int 销售机会金额
  winRate?: OpportunityWinRateEnum // 否 array 赢单率
  estimatedOrderTimeFrom?: string // 否 string 预计下单开始时间（格式为2020-04-17 16:46:45）
  estimatedOrderTimeTo?: string // 否 string 预计下单结束时间（格式为2020-04-17 16:46:45）
  taskCreateBeginTime?: string // 否 string 任务开始时间（格式为2020-04-17 16:46:45）
  taskCreateEndTime?: string // 否 string 任务结束时间（格式为2020-04-17 16:46:45）
  taskStatus?: TaskStatusEnum // 否 int 任务状态，0表示未处理，1表示已处理，默认全部
  taskName?: string // 任务名称
  ruleType?: number // 否 int 规则类型
  ruleId?: number // 否 int 规则id
}

export interface IndicatorCustomer {
  customerId: number //  必须
  cid: string //  必须
  name: string //  必须
  customerType: number //  必须
  createTime: string //  必须
  follower: string //  必须
  lastFollower: string //  必须
  firstAuthTime: string //  必须
  lastFollowTime: string //  必须
  publicTagIds: string //  必须
  privateTagIds: string //  必须
  provinceCode: string //  必须
  cityCode: string //  必须
  predictFollowTime: string //  必须
  lastRelatedTime: string //  必须
  publicTagId: string //  必须
  publicTagName: string //  必须 等于 privateTags.map(item => item.privateTagName).join(',')
  privateTagId: string //  必须
  privateTagName: string //  必须 等于 publicTags.map(item => item.publicTagName).join(',')
  groupIds: string //  必须 等于 groups.map(item => item.groupId).join(',')
  groupName: string //  必须 等于 groups.map(item => item.groupName).join(',')
  groups: {
    groupId: number //  必须
    groupName: string //  必须
    creator: string //  必须
    manager: string //  必须
    belongModule: number //  必须
    companyId: number //  必须
    createTime: string //  必须
    updateTime: string //  必须
    groupMember: string //  必须
    qidianCallNumber: string //  必须
    status: number //  必须
    agentId: string //  必须
    superiorGroupId: number //  必须
    isAcceptLead: number //  必须
  }[]
  publicTags: string[]
  privateTags: string[]
  companyId: number //  必须
  companyName: string //  必须
}
